<template>
    <b-overlay
      :show="[FORM_STATUS.INITIALIZING, FORM_STATUS.LOADING].includes(formStatus)"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
    <b-card title="Настройки меню">
      <div class="menu-settings-wrapper">
        <validation-observer ref="menuSettingsValidation">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Название заведения *"
                  label-for="menu-settings-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Название заведения"
                    rules="required"
                  >
                    <b-form-input
                      id="menu-settings-name"
                      v-model="formData.name"
                      :state="errors.length > 0 ? false : null"
                      name="menu-settings-name"
                      placeholder="Бургерная"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Время работы"
                  label-for="menu-settings-working-hours"
                >
                  <b-form-input
                    id="menu-settings-working-hours"
                    v-model="formData.working_hours"
                    name="menu-settings-working-hours"
                    placeholder="11:00 - 23:00 (открыто)"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Доступы от Wi-Fi"
                  label-for="menu-settings-wifi"
                >
                  <b-form-input
                    id="menu-settings-wifi"
                    v-model="formData.wifi"
                    name="menu-settings-wifi"
                    placeholder="choyga/123456"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Процент обслуживания"
                  label-for="menu-settings-service-percentage"
                >
                  <b-form-input
                    id="menu-settings-service-percentage"
                    type="number"
                    min="0"
                    max="100"
                    v-model="formData.service_percentage"
                    name="menu-settings-service-percentage"
                    placeholder="15"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  label="Адрес электронного меню *"
                  label-for="menu-settings-alias"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Адрес электронного меню"
                    rules="required"
                  >
                    <b-input-group prepend="https://choyga.uz/menu/">
                      <b-form-input
                        id="menu-settings-alias"
                        v-model="formData.alias"
                        name="menu-settings-alias"
                        placeholder="burgernaya"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-input-group>
                    <b-form-text>Ссылка, по которой гости смогут открыть меню. Ссылка не должна быть занята другим заведением.</b-form-text>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col class="mb-1" cols="12">
                <b-form-group
                  label="Логотип"
                  label-for="menu-settings-logo"
                  class="image-uploader"
                >
                  <b-img
                    v-if="logoUrl"
                    class="image-uploader-thumbnail"
                    thumbnail
                    fluid
                    :src="logoUrl"
                    width="200"
                    height="200"
                  />
                  <div class="image-uploader-controllers">
                    <b-form-file
                      id="menu-settings-logo"
                      v-model="formData.logo"
                      placeholder="Выберите файл или скиньте его сюда..."
                      drop-placeholder="Скиньте файл сюда..."
                      name="menu-settings-logo"
                      browse-text="Изменить"
                    />
                    <!-- <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      @click="removeLogoFile"
                    >
                      Удалить
                    </b-button> -->
                  </div>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  @click="submitFormData"
                >
                  Сохранить
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions } from 'vuex'
import {
  BRow, BCol, BFormGroup, BFormText, BFormInput, BForm, BButton, BInputGroup, BFormFile, BImg, BOverlay
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { BCard } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { getApiErrMsg } from '@core/utils/utils'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const FORM_STATUS = {
  INITIAL: 1,
  INITIALIZING: 2,
  LOADING: 3,
  SUCCESS: 4,
  ERROR: 5,
}

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormText,
    BFormInput,
    BForm,
    BButton,
    BInputGroup,
    BFormFile,
    BImg,
    ToastificationContent,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      FORM_STATUS,
      formStatus: FORM_STATUS.INITIAL,
      formData: {
        id: null,
        name: '',
        alias: '',
        logo: null,
        working_hours: null,
        wifi: null,
        service_percentage: null,
      },
    }
  },
  computed: {
    logoUrl() {
      return this.formData.logo ? URL.createObjectURL(this.formData.logo) : ''
    }
  },
  methods: {
    ...mapActions([
      'menu/getMenu',
      'menu/updateMenu',
    ]),
    removeLogoFile() {
      this.formData.logo = null
    },
    async getLogoFileByUrl(url) {
      const res = await fetch(url)
      const fileBlob = await res.blob()
      const fileName = url.substring(url.lastIndexOf('/') + 1)

      return new File([fileBlob], fileName)
    },
    async serializeFormInitialData(data) {
      const fields = {
        id: 'id',
        name: 'name',
        alias: 'alias',
        working_hours: 'working_hours',
        wifi: 'wifi',
        service_percentage: 'service_percentage',
      }

      for (const field in fields) {
        this.formData[field] = data[fields[field]]
      }

      this.formData.logo = null

      if (data.logo) {
        this.formData.logo = await this.getLogoFileByUrl(data.logo)
      }
    },
    async initFormInitialData() {
      this.formStatus = FORM_STATUS.INITIALIZING

      try {
        const res = await useJwt.getMenu()
        const data = res?.data?.[0]

        if (!data) {
          this.formStatus = FORM_STATUS.INITIAL

          return
        }

        await this.serializeFormInitialData(data)

        this.formStatus = FORM_STATUS.INITIAL
      } catch(err) {
        this.formStatus = FORM_STATUS.INITIAL
      }
    },
    getFormData() {
      const data = new FormData()
      const fields = {
        name: 'name',
        alias: 'alias',
        logo: 'logo',
        working_hours: 'working_hours',
        wifi: 'wifi',
        service_percentage: 'service_percentage',
      }

      for (const field in fields) {
        let fieldValue = this.formData[fields[field]]

        if (field === 'logo' && fieldValue === null) {
          fieldValue = '' 
        }

        data.append(field, fieldValue)
      }

      return data
    },
    async submitFormData() {
      const validationRes = await this.$refs.menuSettingsValidation.validate()

      if (!validationRes) {
        return
      }

      this.formStatus = FORM_STATUS.LOADING

      try {
        await useJwt.updateMenu(this.formData.id, this.getFormData(), {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        this.formStatus = FORM_STATUS.INITIAL

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Настройки обновлены.',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch(err) {
        this.formStatus = FORM_STATUS.INITIAL

        const msg = getApiErrMsg(err, 'Не удалось обновить данные, попробуйте еще раз.')

        this.$toast({
          component: ToastificationContent,
          props: {
            title: msg,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  created() {
    this.initFormInitialData()
  }
}
</script>

<style>
.menu-settings-wrapper {
  max-width: 500px;
}

.image-uploader-thumbnail {
  margin-bottom: 1rem;
  max-height: none;
  height: 200px;
  width: 200px;
  object-fit: contain;
  object-position: center;
}

.image-uploader-controllers {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.image-uploader .custom-file {
  width: 200px;
}

.image-uploader .custom-file-label {
  padding: 0;
  position: static;
  width: 100%;
}

.image-uploader .custom-file-label::after {
  position: static;
  border-left: none;
  padding: 0.786rem 1.5rem;
  text-align: center;
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
  cursor: pointer;
}

.image-uploader .custom-file-input {
  position: absolute;
  top: 0;
  left: 0;
}

.image-uploader .form-file-text {
  display: none !important;
}
</style>
